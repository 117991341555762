<template>
  <div>
    <div class="cart-btn" @click.prevent="viewCart">
      <span>{{ productQuantity }}</span>
      <eva-icon width="40" height="40" name="shopping-cart-outline" />
    </div>

    <!-- 購物車 & 訂單 Modal -->
    <div
      class="modal fade"
      id="cartModal"
      tabindex="-1"
      role="dialog"
      data-keyboard="false"
      data-backdrop="static"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h6 v-if="orderStep === '1'" class="modal-title">
              請確認品項與數量
            </h6>
            <h6 v-else-if="orderStep === '2'" class="modal-title">
              送貨日期及留言備註
            </h6>
            <h6 v-else class="modal-title">
              訂單完成
            </h6>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div v-if="orderStep === '1'" class="cart-product-list">
              <div v-for="(product, index) in cart" :key="index" class="product">
                <div class="product-info">
                  <div class="product-name">{{ product.parT_NAM }}</div>
                  <div style="font-size: 13px">
                    {{ product.uniT_NAME }}&emsp;{{ product.parT_NOX }}
                  </div>
                  <input type="number" min="1" v-model="product.salE_QTY" />
                </div>
                <div class="delete">
                  <eva-icon name="trash-2-outline" fill="#FFF" @click="deleteProduct(product)" />
                </div>
              </div>
            </div>
            <div v-else-if="orderStep === '2'" class="cart-info">
              <div class="field">
                <label class="w-100">
                  <span style="display: block; font-weight: 600;">預計到貨日</span>
                  <select class="delivery-date-style" v-model="order.salE_DTE">
                    <option
                      class="delivery-date-option"
                      v-for="(item, index) in deliveryDate"
                      :key="index"
                      :value="item"
                    >
                      {{ item.split(",")[0] + item.split(",")[1] }}
                    </option>
                  </select>
                </label>
              </div>
              <div class="field">
                <label class="w-100">
                  <span style="font-weight: 600;">
                    留言備註<span style="color: red">(最多僅能輸入20字)</span>
                  </span>
                  <textarea
                    type="text"
                    row="3"
                    maxlength="20"
                    placeholder="請輸入備註"
                    v-model="order.docX_RMK"
                  />
                </label>
              </div>
              <ul style="margin-left: -22px; color: #000; font-weight: 600;">
                <li v-if="showNote" style="color: red;">
                  依到貨日訂單累積滿${{ note }}享免運服務。
                </li>
                <li style="color: red;">
                  實際付款金額以[銷貨單]顯示為主。
                </li>
                <li>
                  當日{{
                    customerInfo.limiT_TIME
                  }}前完成下單者，原則上當日配送。(偏遠地區或特殊假日除外)
                </li>
                <li>若有任何疑問請洽當區負責業務或批發中心協助。</li>
              </ul>
            </div>
            <!-- <div v-else-if="orderStep === '3'" class="cart-finish">
              <img src="~@/assets/img/order_complete.png" alt="" />
              <h6>預定送貨日期 {{ order.salE_DTE.split(" ")[0] }}</h6>
              <p v-html="orderMessage.freeShippingStatus"></p>
            </div> -->
            <div v-else class="cart-finish">
              <img src="~@/assets/img/order_complete.png" alt="" />
              <h6>
                <strong>
                  <p>
                    訂單編號<span style="color: red">{{ orderMessage.docX_NOX }}</span>
                  </p>
                  <p>預定送貨日期{{ order.salE_DTE.split(",")[0] }}</p>
                </strong>
              </h6>
              <p class="mt-4" style="font-weight: 600;">
                親愛的客戶您好，您的訂單已成立，我們將盡快安排出貨，感謝您的支持。
              </p>
            </div>
          </div>
          <div class="modal-footer">
            <template v-if="orderStep === '1'">
              <button
                type="button"
                class="btn btn-secondary"
                data-dismiss="modal"
                @click="orderStep = '1'"
              >
                關閉
              </button>
              <button
                v-if="cart.length > 0"
                type="button"
                class="btn btn-primary"
                @click="checkCart"
              >
                確認數量
              </button>
            </template>
            <template v-else-if="orderStep === '2'">
              <button type="button" class="btn btn-secondary" @click.stop="orderStep = '1'">
                返回
              </button>
              <button type="button" class="btn btn-warning" @click.once="createOrder">
                確認日期
              </button>
            </template>
            <!-- <template v-else-if="orderStep === '3'">
              <button type="button" class="btn btn-secondary" @click="deleteOrder">
                回上一步
              </button>
              <button type="button" class="btn btn-warning" @click="confirmOrder">
                訂單確認送出
              </button>
            </template> -->
            <template v-else>
              <button
                type="button"
                class="btn btn-secondary"
                data-dismiss="modal"
                @click="orderStep = '1'"
              >
                關閉
              </button>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import {
  apiCreateOrderGuid,
  // apiDeleteOrder,
  apiConfirmOrder,
  apiGetEstimatedDeliveryDate
} from "@/apis/order";

export default {
  name: "Cart",
  data() {
    return {
      // 客戶資訊
      customerInfo: {},

      // 購物車內商品
      cart: [],
      // 購物車內整理過後的商品
      products: "",
      // 購物車內商品總數
      productQuantity: 0,

      // 訂單步驟
      orderStep: "1",

      guid: null,

      // 訂單
      order: {
        salE_DTE: "", // 送貨日期
        docX_RMK: "" // 備註
      },
      // 可選預計到貨日期
      deliveryDate: [],
      showNote: null,
      note: "",
      // 建立訂單後回傳資訊
      orderMessage: {},

      // 計時器
      timer: ""
    };
  },

  methods: {
    // 檢視購物車
    viewCart() {
      this.guid = this.getGuid();
      this.cart = JSON.parse(localStorage.CART);
      this.productQuantity = this.cart.length;
      $("#cartModal").modal("show");
    },

    // 確認數量
    checkCart() {
      const filterProduct = this.cart.filter(item => item.salE_QTY < 1);
      this.products = this.cart.map(item => {
        return {
          parT_NOX: item.parT_NOX,
          salE_QTY: item.salE_QTY
        };
      });
      if (filterProduct.length === 0) {
        this.orderStep = "2";
        this.order.salE_DTE = "";
        this.order.docX_RMK = "";
        this.getTimer();
        this.getEstimatedDeliveryDate();
      } else {
        this.$notify({
          title: "錯誤",
          message: "商品數量不得小於1",
          type: "error",
          duration: 2500
        });
      }
    },

    // 計時器
    getTimer() {
      this.timer = setTimeout(() => {
        this.orderStep = "1";
      }, 1800000);
    },

    // 取得預計到貨日
    getEstimatedDeliveryDate() {
      apiGetEstimatedDeliveryDate({
        CustomerId: this.customerInfo.cusT_ID,
        Products: this.products
      }).then(response => {
        if (response.status === 200) {
          this.deliveryDate = response.data.estimatedArrivalDateList;
          this.order = {
            salE_DTE: this.deliveryDate[0]
          };
          this.showNote = response.data.isFreeShipShow;
          this.note = response.data.freeShippingLimit;
        }
      });
    },

    // 建立訂單
    createOrder() {
      apiCreateOrderGuid({
        token: this.guid,
        account: localStorage.ACCOUNT,
        docX_NOX: "", // 預設傳入空字串
        docX_DTE: this.dayjs().format("YYYY-MM-DDTHH:mm:ss"),
        stoR_CDX: this.customerInfo.stoR_CDX,
        ordX_SRC: localStorage.USER_GUID ? "1" : "0",
        ordX_CST: localStorage.ACCOUNT,
        cusT_NOX: this.customerInfo.cusT_NOX,
        docX_STS: "W",
        docX_RMK: this.order.docX_RMK,
        salE_DTE: this.order.salE_DTE.split(",")[0],
        l_List_CTB_APP_SSWOD: this.cart,
        VERSION: 'WEB',
      }).then(response => {
        if (response.status === 200) {
          if (response.data.errorMessage) {
            this.$notify({
              title: "錯誤",
              message: response.data.errorMessage,
              type: "error",
              duration: 2500
            });
            this.order.salE_DTE = "";
            this.getEstimatedDeliveryDate();
          } else {
            this.orderStep = "3";
            this.orderMessage = response.data;
            this.confirmOrder();
          }
        }
      });
    },

    // 成立訂單
    confirmOrder() {
      apiConfirmOrder({
        account: localStorage.ACCOUNT,
        docX_NOX: this.orderMessage.docX_NOX,
        docX_DTE: this.dayjs().format("YYYY-MM-DDTHH:mm:ss"),
        stoR_CDX: this.customerInfo.stoR_CDX,
        ordX_SRC: localStorage.USER_GUID ? "1" : "0",
        ordX_CST: localStorage.ACCOUNT,
        cusT_NOX: this.customerInfo.cusT_NOX,
        docX_STS: "",
        docX_RMK: this.order.docX_RMK,
        salE_DTE: this.order.salE_DTE.split(",")[0],
        l_List_CTB_APP_SSWOD: this.cart.forEach(
          (item, index, arr) => (arr[index].docX_NOX = this.orderMessage.docX_NOX)
        )
      }).then(response => {
        if (response.status === 200) {
          this.cart = [];
          localStorage.setItem("CART", JSON.stringify([]));
          this.$store.dispatch("updateCart", this.cart);
          this.$store.dispatch("getCartProductQuantity", this.cart.length);
        }
      });
    },

    // 隨機取得Guid
    getGuid() {
      let d = Date.now();
      if (
        typeof performance !== "undefined" &&
        typeof performance.now === "function"
      ) {
        d += performance.now();
      }
      return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
        /[xy]/g,
        function (c) {
          let r = (d + Math.random() * 16) % 16 | 0;
          d = Math.floor(d / 16);
          return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
        }
      );
    },

    // 刪除訂單
    // deleteOrder() {
    //   apiDeleteOrder({
    //     account: localStorage.ACCOUNT,
    //     docX_NOX: this.orderMessage.docX_NOX,
    //     docX_DTE: this.dayjs().format("YYYY-MM-DDTHH:mm:ss"),
    //     stoR_CDX: this.customerInfo.stoR_CDX,
    //     ordX_SRC: localStorage.USER_GUID ? "1" : "0",
    //     ordX_CST: localStorage.ACCOUNT,
    //     cusT_NOX: this.customerInfo.cusT_NOX,
    //     docX_STS: "",
    //     docX_RMK: this.order.docX_RMK,
    //     salE_DTE: this.order.salE_DTE.split(" ")[0],
    //     l_List_CTB_APP_SSWOD: this.cart.forEach(
    //       (item, index, arr) => (arr[index].docX_NOX = this.orderMessage.docX_NOX)
    //     )
    //   }).then(response => {
    //     if (response.status === 200) {
    //       this.orderStep = "2";
    //       this.orderMessage = {};
    //       this.shippingFee = 0;
    //     }
    //   });
    // },

    // 取得日期區間
    getDates(startDate, endDate) {
      const dateArray = [];
      let currentDate = startDate;
      if (endDate < currentDate) {
        return dateArray;
      }
      while (currentDate <= endDate) {
        dateArray.push(currentDate);
        currentDate = this.dayjs(currentDate)
          .add(1, "day")
          .format("YYYY-MM-DD");
      }
      return dateArray;
    },

    // 刪除商品
    deleteProduct(product) {
      let sameProduct = this.cart.findIndex(element => element.parT_NOX === product.parT_NOX);
      this.cart.splice(sameProduct, 1);
      this.cart.forEach((item, index) => {
        item.iteM_XXX = ++index;
      });

      localStorage.setItem("CART", JSON.stringify(this.cart));
      this.$store.dispatch("getCartProductQuantity", this.cart.length);
      this.$store.commit("UPDATE_CART", true);
    }
  },

  computed: {
    ...mapGetters(["updateCart"]),
    ...mapGetters(["cartProductQuantity"])
  },

  watch: {
    // 購物車內商品總數
    cartProductQuantity(val) {
      this.productQuantity = val;
    },

    orderStep(val) {
      if (val === "1") {
        clearTimeout(this.timer);
      }
    }
  },

  created() {
    this.customerInfo = JSON.parse(localStorage.CUSTOMER_INFO);
    // 取得購物車內商品
    if (localStorage.CART) {
      this.productQuantity = JSON.parse(localStorage.CART).length;
    }
  },

  destroyed() {
    clearTimeout(this.timer);
  }
};
</script>

<style lang="scss">
.page-wrap {
  display: flex;
  .page-side-bar {
    width: 250px;
    transition: all 0.3s;
    .menu-btn {
      display: none;
    }

    @media (max-width: 767px) {
      position: fixed;
      top: 0;
      left: 0;
      transform: translateX(-100%);
      z-index: 99;
      .menu-btn {
        display: block;
        position: absolute;
        right: 0;
        background-color: #e60012;
        color: #fff;
        width: 50px;
        top: 0;
        height: 50px;
        line-height: 50px;
        text-align: center;
        cursor: pointer;

        transform: translateX(100%);
        svg {
          fill: #4686c2;
        }
      }

      &.open {
        transform: translateX(0);
      }
    }

    .page-side-bar-inner {
      background-color: #e60012;
      height: 100vh;
      overflow-y: auto;

      .site-name {
        text-align: center;
      }
      .site-menu {
        padding: 40px 0;
        span {
          display: block;
          padding: 15px 20px;
          color: #fff;
          cursor: pointer;
          i {
            margin-right: 10px;
          }

          // &.router-link-exact-active {
          //   background-color: rgba(#000, 0.1);
          //   border-left: 5px solid #fff;
          // }
        }
        a {
          display: block;
          padding: 15px 20px;
          color: #fff;
          i {
            margin-right: 10px;
          }

          &.router-link-exact-active {
            background-color: rgba(#000, 0.1);
            border-left: 5px solid #fff;
          }
        }
      }
    }
  }
  .page-content {
    flex: 1;
    min-width: 1px;
    height: 100vh;
    overflow-y: auto;
  }
}

.cart-btn {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 99;
  background-color: #e60012;
  padding: 3px;
  border-radius: 5px;
  cursor: pointer;
  box-shadow: -3px 3px 7px rgba(0, 0, 0, 0.2);
  i {
    padding: 6px;
    svg {
      fill: #fff;
    }
  }
  span {
    display: block;
    text-align: center;
    // padding: 2px;
    background-color: #fff;
    color: #e60012;
    font-weight: bold;
    font-size: 16px;
  }
}

.cart-info {
  .field {
    margin-bottom: 20px;

    textarea {
      background-color: transparent;
      outline: none;
      font-weight: 600;
      border: 1px solid #ccc;
    }
  }
}

.cart-finish {
  text-align: center;
  padding: 0 40px 10px 40px;

  img {
    width: 180px;
  }
  h5 {
    line-height: 175%;
    margin-bottom: 20px;
    span {
      color: #e60012;
    }
  }
}
</style>

<style lang="scss" scoped>
.delivery-date-style {
  width: 100%;
  height: 40px;
  padding-left: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-weight: 600;
  opacity: 0.7;
}

.delivery-date-style:focus-visible {
  outline: 1px solid #ccc;
}

.delivery-date-option {
  font-weight: 600;
  color: #606266;
}
/deep/ .el-select-dropdown__item {
  font-weight: 600;
}

/deep/ .el-input__inner {
  color: #000;
}
</style>
