<template>
  <div class="page-wrap">
    <div class="page-side-bar" :class="{ open: menuOpen === true }">
      <div class="menu-btn" @click.prevent="menuOpen = !menuOpen">三</div>
      <div class="page-side-bar-inner">
        <div class="site-name">
          <img src="~@/assets/img/foodsmart_logo.png" alt="" />
          <h6 class="mt-2" style="color: #fff">{{ customerInfo.cusT_SNM }}</h6>
        </div>
        <div class="site-menu">
          <router-link :to="{ path: 'product' }">
            <eva-icon fill="#FFF" name="archive-outline"></eva-icon>
            商品列表
          </router-link>

          <router-link :to="{ path: 'frequently-bought' }">
            <eva-icon fill="#FFF" name="bookmark-outline" />
            常購商品
          </router-link>

          <router-link :to="{ path: 'order-history' }">
            <eva-icon fill="#FFF" name="clock-outline" />
            歷史訂單
          </router-link>

          <router-link :to="{ path: 'message' }">
            <eva-icon fill="#FFF" name="email-outline" />
            訊息通知
          </router-link>

          <router-link
            v-if="permission"
            :to="{ path: 'push-message' }"
          >
            <eva-icon fill="#FFF" name="bell-outline" />
            推播管理
          </router-link>

          <router-link :to="{ path: 'information' }">
            <eva-icon fill="#FFF" name="info-outline" />
            個人資訊
          </router-link>

          <router-link v-if="visible" :to="{ path: 'select-customer' }">
            <eva-icon fill="#FFF" name="person-outline" />
            選擇客戶
          </router-link>

          <a :href="customerInfo.weB_URL" target="_blank">
            <eva-icon fill="#FFF" name="video-outline" />
            教學影片
          </a>

          <span @click="logout">
            <eva-icon fill="#FFF" name="power-outline" />
            登出
          </span>

        </div>
      </div>
    </div>
    <div class="page-content">
      <router-view></router-view>
    </div>

    <Cart />
  </div>
</template>

<script>
import Cart from "@/components/Cart.vue";

export default {
  name: "Layout",
  data() {
    return {
      // 客戶資訊
      customerInfo: {},

      // 權限
      permission: null,

      // menu是否可見
      visible: "",

      menuOpen: false
    };
  },

  methods: {
    // 登出
    logout() {
      this.$router.push({ path: "login" });
      const rememberAccount = localStorage.REMEMBER_ACCOUNT;
      const account = localStorage.ACCOUNT;
      localStorage.clear();
      localStorage.setItem("REMEMBER_ACCOUNT", rememberAccount);
      sessionStorage.setItem('LOGOUT', true);
      if (rememberAccount === "true") {
        localStorage.setItem("ACCOUNT", account);
      }
    }
  },

  created() {
    this.visible = localStorage.USER_GUID;
    this.permission = localStorage.PUSH_MESSAGE_PERMISSION;
    this.customerInfo = JSON.parse(localStorage.CUSTOMER_INFO);
  },

  components: { Cart }
};
</script>

<style lang="scss">
.page-wrap {
  display: flex;
  .page-side-bar {
    width: 250px;
    transition: all 0.3s;
    .menu-btn {
      display: none;
    }

    @media (max-width: 767px) {
      position: fixed;
      top: 0;
      left: 0;
      transform: translateX(-100%);
      z-index: 99;
      .menu-btn {
        display: block;
        position: absolute;
        right: 0;
        background-color: #e60012;
        color: #fff;
        width: 50px;
        top: 0;
        height: 50px;
        line-height: 50px;
        text-align: center;
        cursor: pointer;

        transform: translateX(100%);
        svg {
          fill: #4686c2;
        }
      }

      &.open {
        transform: translateX(0);
      }
    }

    .page-side-bar-inner {
      position: relative;
      background-color: #e60012;
      height: 100vh;
      overflow-y: auto;

      .site-name {
        text-align: center;
      }
      .site-menu {
        padding: 30px 0;
        a {
          display: block;
          padding: 15px 20px;
          color: #fff;
          i {
            margin-right: 10px;
          }

          &.router-link-exact-active {
            background-color: rgba(#000, 0.1);
            border-left: 5px solid #fff;
          }
        }
      }
    }
  }
  .page-content {
    flex: 1;
    min-width: 1px;
    height: 100vh;
    overflow-y: auto;
  }
}
</style>
